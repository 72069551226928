import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import {
  ContentfulPage,
  ContentfulMedia,
  ContentfulText,
  ContentfulList,
} from '../../../types/graphql-types'
import SEO from '../../components/SEO'
import LayoutBlank from '../../components/Layout/Blank'
import Footer from '../../components/Footer/Email'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

export const query = graphql`
  query LivelyAppAccountNoContractWelcome {
    contentfulPage(
      slug: { eq: "/emails/lively-app-account-no-contract-welcome/" }
      externalName: { eq: "Email Web View" }
    ) {
      externalName
      slug
      seoTitle
      seoDescription
      noindex
      type
      gtmPageType
      references {
        ... on ContentfulMedia {
          __typename
          id
          name
          externalName
          altText
          media {
            file {
              url
            }
          }
        }
        ... on ContentfulText {
          __typename
          id
          name
          externalName
          longSimpleText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulList {
          __typename
          id
          name
          externalName
          items {
            ... on ContentfulMedia {
              __typename
              id
              name
              externalName
              altText
              media {
                file {
                  url
                }
              }
              link {
                type
                displayName
                ctaLink
              }
            }
          }
        }
      }
    }
  }
`

const ContentWrapper = styled.article`
  ${tw`my-0 mx-auto`}
  width: 600px;

  .hero {
    ${tw`mb-4`}
  }

  .intro {
    h2 {
      ${tw`text-2xl mb-2 text-center`}
    }
    p {
      ${tw`text-left`}
    }
  }

  .billing-info {
    ${tw`p-8`}
    background: rgba(216, 216, 216, 0.2);

    h2 {
      ${tw`text-2xl mb-4`}
    }

    a {
      ${tw`text-lively-blue font-semibold`}
    }
  }
`

const LivelyAppAccountNoContractWelcome: React.FC<Props> = ({
  data,
}: Props) => {
  return (
    <>
      <LayoutBlank>
        <SEO
          title={data.contentfulPage.seoTitle as string}
          description={data.contentfulPage.seoDescription as string}
          noIndex={true}
        />
        <ContentWrapper>
          {data.contentfulPage.references?.map(content => {
            const media = content as ContentfulMedia
            const copy = content as ContentfulText
            const list = content as ContentfulList

            if (media.externalName === 'Brand Logo') {
              return (
                <header key={media.id}>
                  <img
                    className="h-auto"
                    src={media.media?.file?.url as string}
                    alt={media.altText as string}
                  />
                </header>
              )
            }
            if (media.externalName === 'Email Hero') {
              return (
                <section key={media.id} className="hero">
                  <h1>
                    <img
                      className="h-auto"
                      src={media.media?.file?.url as string}
                      alt={media.altText as string}
                    />
                  </h1>
                </section>
              )
            }
            if (copy.externalName === 'Intro') {
              return (
                <section key={copy.id} className="intro">
                  <div
                    key={copy.id}
                    dangerouslySetInnerHTML={{
                      __html: copy.longSimpleText?.childMarkdownRemark
                        ?.html as string,
                    }}
                  ></div>
                </section>
              )
            }
            if (list.externalName === 'List') {
              return (
                <section className="mb-12" key={list.id}>
                  <ul className="grid grid-cols-3 place-items-center gap-1">
                    {(list.items as ContentfulMedia[]).map(item => {
                      return (
                        <li key={item?.id} className="place-self-end">
                          <img
                            className="mb-8 block max-h-56"
                            alt={item?.altText as string}
                            src={item?.media?.file?.url as string}
                          />
                          <a
                            className="btn-primary mx-auto my-0 block w-full"
                            href={item?.link?.ctaLink as string}
                          >
                            {item?.link?.displayName}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </section>
              )
            }
            if (copy.externalName === 'Billing Info') {
              return (
                <section className="billing-info" key={copy.id}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: copy.longSimpleText?.childMarkdownRemark
                        ?.html as string,
                    }}
                  ></div>
                </section>
              )
            }
          })}
        </ContentWrapper>
      </LayoutBlank>
      {data.contentfulPage.references?.map(content => {
        const footerCopy = content as ContentfulText

        if (footerCopy.externalName === 'Copyright') {
          const copyrightText = (
            <div
              className="copyright mb-0"
              key={footerCopy.id}
              dangerouslySetInnerHTML={{
                __html: footerCopy.longSimpleText?.childMarkdownRemark
                  ?.html as string,
              }}
            ></div>
          )
          return <Footer key={footerCopy.id} copyright={copyrightText} />
        }
      })}
    </>
  )
}

export default LivelyAppAccountNoContractWelcome
